'use client';
import { motion } from 'framer-motion';
import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Link from 'next/link';
import brandsFile from '@/utils/brands';
import useLocalStorage from '@/components/Hooks/UseLocalStorage';
import classes from './SearchDiscovery.module.css';
import Listing from '@/components/Carousels/Listing';
import { GrClose } from 'react-icons/gr';
import { useListingsContext } from '@/context/listings';

const SearchDiscovery = (props) => {
	const { searchTerm, setActive, suggestions, querySuggestions, onChange } =
		props;

	const { listings } = useListingsContext();

	const [recentSearches, setRecentSearches] = useLocalStorage('searchTerm', []);
	const [brands, setBrands] = useState([]);

	const [displayBrands, setDisplayBrands] = useState([]);

	const [categories, setCategories] = useState([]);
	const [displayCategories, setDisplayCategories] = useState([]);

	useEffect(() => {
		let filteredBrands = [];
		if (brandsFile.length > 0 && !listings.all.loading) {
			for (let brand of listings.all.uniqueBrands) {
				for (let b of brandsFile) {
					if (b.link && b.brand.toString() === brand.name.toString()) {
						filteredBrands.push(b);
					}
				}
			}
			setBrands(filteredBrands);
		}
	}, [listings.all.loading, brandsFile]);

	useEffect(() => {
		let filteredBrands = [...new Set(brands)];

		if (searchTerm === '') {
			setDisplayBrands([]);
		} else if (searchTerm && searchTerm !== '' && brands) {
			const fuse = new Fuse(brands, {
				keys: ['brand'],
				threshold: 0.1,
			});

			const results = fuse.search(searchTerm);
			filteredBrands = results.map((result) => result.item);
			setDisplayBrands(filteredBrands.slice(0, 1));
		}
	}, [searchTerm]);

	useMemo(() => {
		let cat = [
			'Decor',
			'Decorative Accessories',
			'Vases',
			'Sculptures & Statues',
			'Decorative Accents',
			'Kitchen Accessoires',
			'Room Dividers',
			'Mirrors',
			'Wall Mirrors',
			'Full Length & Floor Mirrors',
			'Rugs & Carpets',
			'Runners',
			'Carpets',
			'Wall Art',
			'Paintings',
			'Picture Frames',
			'Wall Decorative Accents',
			'Seating',
			'Chairs',
			'Accent Chairs',
			'Office Chairs',
			'Swivel Chairs',
			'Dining Chairs',
			'Sofas',
			'Loveseats',
			'Sofas',
			'Sectionals',
			'Armchairs',
			'Arm Chairs',
			'Rocking Chairs',
			'Club Chairs',
			'Recliners',
			'Benches',
			'Stools',
			'Low Stools',
			'Bar Stools',
			'Ottomans & Footstools',
			'Chaises & Daybeds',
			'Tables & Desks',
			'Tables',
			'Coffee tables',
			'Console tables',
			'Accent & Side tables',
			'Dining tables',
			'Desks',
			'Desks',
			'Secretary Desks',
			'Vanity Desks',
			'Storage',
			'Nightstands',
			'Dressers & Chests of Drawers',
			'Storage & Display Cabinets',
			'Bookcases & Shelving',
			'Trunks & Chests',
			'Armoires & Wardrobes',
			'Sideboards & Credenzas',
			'Bar Carts',
			'Lighting',
			'Table Lamps',
			'Desk Lamps',
			'Ceiling & Wall Lamps',
			'Floor Lamps',
			'Beds',
			'Headboards',
			'Bed Frames',
		];

		setCategories(cat);
	}, []);

	useEffect(() => {
		let filteredListings = [...new Set(categories)];

		if (searchTerm === '') {
			setDisplayCategories([]);
		} else if (searchTerm && searchTerm !== '') {
			const fuse = new Fuse(
				filteredListings.map((value) => value.toString()),
				{
					keys: [],
					threshold: 0.1,
				}
			);

			const results = fuse.search(searchTerm);
			filteredListings = results.map((result) => result.item);
			setDisplayCategories(filteredListings.slice(0, 3));
		}
	}, [searchTerm]);

	const limitedSuggestions = suggestions.slice(0, 6);

	const [showSection, setShowSection] = useState();

	const [innerWidth, setInnerWidth] = useState();

	useEffect(() => {
		setInnerWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		setShowSection(
			(limitedSuggestions.length <= 0 && innerWidth < 600) || innerWidth > 600
		);
	}, [limitedSuggestions, innerWidth]);

	function formatHighlightedString(htmlString) {
		return {
			__html: htmlString
				?.replace(/<em>/g, '<mark class="highlight-mark">')
				.replace(/<\/em>/g, '</mark>'),
		};
	}

	return (
		<>
			<motion.div
				onClick={() => setActive(false)}
				className={classes.active}
				initial={{ opacity: 0, x: '-100px' }}
				animate={{ opacity: 1, x: 0 }}
				transition={{
					duration: 0.4,
					type: 'spring',
					stiffness: 400,
					damping: 40,
				}}
			>
				<GrClose className={classes.closeIcon} />

				{showSection && !querySuggestions.length ? (
					<section className={classes.row}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={showSection ? { opacity: 1 } : { opacity: 0 }}
							transition={{
								duration: 1,
								type: 'spring',
								stiffness: 400,
								damping: 40,
							}}
							className={classes.col}
						>
							<section className={classes.headerSectionJustify}>
								<div className={classes.header}>Popular searches </div>
							</section>

							<div
								onClick={() => setActive(false)}
								className={classes.badgeBox1}
							>
								<Link
									className={classes.link}
									onClick={() => onChange('Sofas')}
									href={`/collections/seating/sofas/sofas`}
								>
									<div className={classes.badge1}>Sofas</div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Sideboards & Credenzas')}
									href={`/collections/storage/sideboards-and-credenzas`}
								>
									<div className={classes.badge1}>Sideboards & Credenzas </div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Nightstands')}
									href={`/collections/storage/nightstands`}
								>
									<div className={classes.badge1}>Nightstands </div>
								</Link>
								<Link
									onClick={() => onChange('West Elm')}
									className={classes.link}
									href={`/brand/west-elm`}
								>
									<div className={classes.badge1}>West Elm</div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Chairs')}
									href={`/collections/seating/chairs`}
								>
									<div className={classes.badge1}>Chairs</div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Benches')}
									href={`/collections/seating/benches`}
								>
									<div className={classes.badge1}>Benches</div>
								</Link>

								<Link
									className={classes.link}
									onClick={() => onChange('Pottery Barn')}
									href={`/brand/pottery-barn`}
								>
									<div className={classes.badge1}>Pottery Barn</div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Dressers')}
									href={`/collections/storage/dressers-and-chests-of-drawers`}
								>
									<div className={classes.badge1}>Dressers</div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Rugs')}
									href={`/collections/decor/rugs-and-carpets`}
								>
									<div className={classes.badge1}>Rugs</div>
								</Link>

								<Link
									className={classes.link}
									onClick={() => onChange('Mirrors')}
									href={`/collections/decor/mirrors`}
								>
									<div className={classes.badge1}>Mirrors</div>
								</Link>
								<Link
									className={classes.link}
									href={`/brand/herman-miller`}
									onClick={() => onChange('Herman Miller')}
								>
									<div className={classes.badge1}>Herman Miller</div>
								</Link>
								<Link
									className={classes.link}
									onClick={() => onChange('Desks')}
									href={`/collections/tables-and-desks/desks`}
								>
									<div className={classes.badge1}>Desks</div>
								</Link>
								<Link
									className={classes.link}
									href={`/collections/seating/stools`}
									onClick={() => onChange('Stools')}
								>
									<div className={classes.badge1}>Stools</div>
								</Link>
							</div>
						</motion.div>
						<>
							{recentSearches && recentSearches.length > 0 && (
								<section className={classes.col}>
									<div className={classes.header}>
										<span>Recent searches</span>
									</div>
									<div
										className={classes.badgeBox1}
										onClick={() => setActive(false)}
									>
										{Object.keys(recentSearches)
											.reverse()
											.slice(0, 10)
											.map((term, i) => (
												<Link
													key={i}
													className={classes.link}
													href={`/collections?keyword=${recentSearches[term].searchTerm}`}
												>
													<div key={i} className={classes.badge1}>
														{recentSearches[term].searchTerm}
													</div>
												</Link>
											))}
									</div>
								</section>
							)}
						</>
					</section>
				) : (
					<section className={classes.row}>
						{querySuggestions.length > 0 && (
							<div className={classes.col}>
								<section className={classes.headerSectionJustify}>
									<div className={classes.header}>Search Suggestions</div>
								</section>

								<div
									onClick={() => setActive(false)}
									className={classes.badgeBox1}
								>
									{querySuggestions.map((suggestion) => (
										<Link
											key={suggestion.objectID}
											className={classes.link}
											onClick={() => onChange(suggestion.query)}
											href={`/collections?keyword=${suggestion.query}`}
										>
											<div
												className={classes.badge2}
												dangerouslySetInnerHTML={formatHighlightedString(
													suggestion._highlightResult.query?.value
												)}
											/>
										</Link>
									))}
								</div>
							</div>
						)}

						<>
							{recentSearches &&
								recentSearches.length > 0 &&
								!querySuggestions.length &&
								showSection && (
									<section className={classes.col}>
										<div className={classes.header}>
											<span>Recent searches</span>
										</div>
										<div
											className={classes.badgeBox1}
											onClick={() => setActive(false)}
										>
											{Object.keys(recentSearches)
												.reverse()
												.slice(0, 10)
												.map((term, i) => (
													<Link
														key={i}
														className={classes.link}
														onClick={() =>
															onChange(recentSearches[term].searchTerm)
														}
														href={`/collections?keyword=${recentSearches[term].searchTerm}`}
													>
														<div key={i} className={classes.badge1}>
															{recentSearches[term].searchTerm}
														</div>
													</Link>
												))}
										</div>
									</section>
								)}
						</>
					</section>
				)}
				<>
					{searchTerm &&
						limitedSuggestions &&
						limitedSuggestions.length > 0 && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{
									duration: 1,
									type: 'spring',
									stiffness: 400,
									damping: 40,
								}}
								className={classes.colWidth100}
							>
								<div className={classes.headerSection}>
									<span className={classes.header}> Products </span>{' '}
									{global?.window && innerWidth > 600 && (
										<div className={classes.line} />
									)}
									<Link
										href={`/collections?keyword=${searchTerm}`}
										className={classes.clear}
									>
										See All
									</Link>
								</div>
								<section className={classes.colSpace0}>
									{global?.window && innerWidth > 600 ? (
										<>
											{limitedSuggestions.map((val, idx) => (
												<Listing listing={val} idx={idx} key={idx} />
											))}
										</>
									) : (
										<>
											{' '}
											{limitedSuggestions.map((val, idx) => (
												<Link
													href={`/product/${val.kashewId}/${val?.title
														?.toLowerCase()
														.replace(/[_\W]+/g, '-')
														.replace(/^-+/, '')
														.replace(/-+$/, '')}`}
													key={idx}
													className={classes.link}
												>
													<div className={classes.searchVal} key={idx}>
														<LazyLoadImage
															loading="lazy"
															src={`${val.images[0]}?tr=w-100,h-100`}
															className={classes.image}
														/>{' '}
														<div className={classes.text}>{val.title}</div>
													</div>
												</Link>
											))}
										</>
									)}
								</section>
							</motion.div>
						)}
				</>

				{showSection && (
					<section className={classes.row}>
						<>
							{searchTerm && displayBrands && displayBrands.length > 0 && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{
										duration: 1,
										type: 'spring',
										stiffness: 400,
										damping: 40,
									}}
									className={classes.col}
								>
									<div className={classes.header}>
										<span>Related Brands</span>
									</div>
									<div className={classes.brandVal}>
										{displayBrands.map((val, idx) => (
											<Link
												className={classes.linkContainer}
												key={idx}
												href={`/brand/${val.brand
													.replace(/ /g, '-')
													.replace(/&/g, 'and')
													.toLowerCase()}`}
											>
												<LazyLoadImage
													loading="lazy"
													className={classes.brandLogo}
													src={val.link}
												/>
											</Link>
										))}
									</div>
								</motion.div>
							)}
						</>
						<>
							{searchTerm &&
								displayCategories &&
								displayCategories.length > 0 && (
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{
											duration: 1,
											type: 'spring',
											stiffness: 400,
											damping: 40,
										}}
										className={classes.col}
									>
										<div className={classes.header}>
											<span>Related Categories</span>
										</div>
										<div
											onClick={() => setActive(false)}
											className={classes.badgeBox2}
										>
											{displayCategories.map((term, i) => (
												<Link
													onClick={() => onChange(term)}
													key={i}
													className={classes.link}
													href={`/collections?keyword=${term.replace(
														' & ',
														' and '
													)}`}
												>
													<div className={classes.badge1}>{term}</div>
												</Link>
											))}
										</div>
									</motion.div>
								)}
						</>
					</section>
				)}
			</motion.div>
		</>
	);
};

export default SearchDiscovery;
